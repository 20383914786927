import { FC, Key, ReactElement, useEffect, useRef, useState } from "react";
import { Button, Input, Layout, message, Row, Space, Table } from "antd";
import type { ColumnsType, TableProps } from "antd/es/table";
import styles from "./CommonFilterTablePagination.module.scss";
import {
  ICommonFilterTablePaginationApi,
  ICommonFilterTablePaginationRes,
  ICommonFilterTablePagination,
  ICommonFilterTableColumn,
  ICommonFilterTableSearchApi,
  ICommonFilterTableSearch,
  ICommonFilterOperator,
  CommonFilterOperationEnum,
} from "../../api/types";
import { useRequest } from "ahooks";
import Filter from "./Filter";
import { nanoid } from "nanoid";
import { useDialogRef } from "../../hooks/useDialog";
import CrudInfoForm from "./CrudInfoForm";
import { ICommonFormInfoRes } from "../../api/common";
import AssignInfoForm from "./AssignInfoForm";
import { executeCrudEvent } from "./operator";

const { Header, Content, Footer } = Layout;

export interface ITableSelectedData {
  selected: Key[];
  refreshList: () => void;
  setCheckedRowKeys: (keys: React.Key[]) => void;
}

export interface IOperationFunc {
  (tableData: ITableSelectedData): any;
}
export interface CommonFilterTablePaginationProps {
  id?: string;
  getListPromise: ICommonFilterTablePaginationApi;
  getSearchPromise: ICommonFilterTableSearchApi;
  rowKey?: string;
  operation?: any;
  operationFuncs?: Record<string, IOperationFunc>;
  onCheckedChange?: (keys: React.Key[]) => void;
}

const CommonFilterTablePagination: FC<CommonFilterTablePaginationProps> = (
  props: CommonFilterTablePaginationProps
) => {
  const [tableBodyHeight, setTableBodyHeight] = useState(0);
  const [hasGetFilters, setHasGetFilters] = useState(false);

  const [columnDatas, setColumnDatas] = useState<
    Array<ICommonFilterTableColumn>
  >([]);
  const [columns, setColumns] = useState<ColumnsType<Record<string, any>>>([]);
  const [columnsWithSorting, setColumnsWithSorting] = useState<
    ColumnsType<Record<string, any>>
  >([]);

  const [sortMap, setSortMap] = useState<Record<string, number>>({});

  const [searchers, setSearchers] = useState<Array<ICommonFilterTableSearch>>(
    []
  );
  const [operators, setOperators] = useState<Array<ICommonFilterOperator>>([]);

  const [tableDatas, setTableDatas] = useState<Array<Record<string, any>>>([]);

  const tableRef = useRef<any>(null);

  const [pagination, setPagination] = useState<
    ICommonFilterTablePagination & {
      total_num: number;
      order_by: Record<string, number>;
      filter_by: Record<string, any>;
    }
  >({
    page_num: 1,
    per_page_num: 10,
    total_num: 0,
    order_by: {},
    filter_by: {},
  });

  const { run: getSearcherRun } = useRequest(
    async () => {
      let res = await props.getSearchPromise();
      setSearchers(res.data.filters);
      if (res.data.operators) {
        setOperators(res.data.operators);
      }
    },
    {
      manual: true,
      onSuccess() {
        setHasGetFilters(true);
      },
    }
  );

  const { data, error, run } = useRequest(
    async () => {
      let res = await props.getListPromise({
        page_num: pagination.page_num,
        per_page_num: pagination.per_page_num,
        order_by: pagination.order_by,
        filter_by: pagination.filter_by,
      });
      setColumnDatas(res.data.columns);
      setTableDatas(res.data.data);
      setPagination((origin) => {
        return {
          ...origin,
          total_num: res.data.total_num,
        };
      });

      // setPagination({
      //   page_num: pagination.page_num,
      //   per_page_num: res.data.per_page_num,
      //   total_num: res.data.total_num,
      //   order_by: {},
      // });
    },
    {
      manual: true,
    }
  );

  const onChange: TableProps<Record<string, any>>["onChange"] = (
    pagination,
    filters,
    sorter,
    extra
  ) => {
    // console.log("params", pagination, filters, sorter, extra);
    let orderBy: Record<string, number> = {};
    if (Array.isArray(sorter)) {
      sorter.forEach((item) => {
        if (item.field) {
          if (item.order) {
            orderBy[item.field as string] = item.order === "ascend" ? 1 : -1;
          } else {
            delete orderBy[item.field as string];
          }
        }
      });
    } else if (typeof sorter === "object") {
      if (sorter.field) {
        if (sorter.order) {
          orderBy[sorter.field as string] = sorter.order === "ascend" ? 1 : -1;
        } else {
          delete orderBy[sorter.field as string];
        }
      }
    }
    setSortMap(orderBy);

    setPagination((state) => {
      return {
        per_page_num: pagination.pageSize,
        page_num: pagination.current || 1,
        total_num: pagination.total || 0,
        order_by: orderBy,
        filter_by: state.filter_by,
      };
    });
  };
  const handleResetSorting = () => {
    setSortMap({});
    setPagination((state) => {
      return {
        ...state,
        order_by: {},
      };
    });
  };

  useEffect(() => {
    const tmpColumns: ColumnsType<Record<string, any>> = [];
    columnDatas.forEach((item) => {
      tmpColumns.push({
        title: item.column_name,
        dataIndex: item.column_value,
        width: item.width || 200,
        sorter: item.can_order
          ? {
              multiple: 1,
            }
          : undefined,
        // sorter: (a, b) => a.age - b.age,
      });
    });
    setColumns(tmpColumns);
  }, [columnDatas]);

  useEffect(() => {
    setColumnsWithSorting(
      columns.map((column: Record<string, any>) => {
        return {
          ...column,
          sortOrder: sortMap[column.dataIndex]
            ? sortMap[column.dataIndex] === 1
              ? "ascend"
              : "descend"
            : null,
        };
      })
    );
  }, [sortMap, columns]);

  const upateTableHeight = (height?: number) => {
    if (tableRef.current) {
      setTableBodyHeight((tableRef.current as any).offsetHeight - 54 - 32 + 16);
    }
  };

  const handleSearch = (filterBy: Record<string, any>) => {
    setPagination((state) => {
      return {
        ...state,
        page_num: 1,
        filter_by: filterBy,
      };
    });
  };

  const crudDialogRef = useDialogRef();
  const crudInfoFormRef = useRef<any>();
  const assignInfoFormRef = useRef<any>();

  const handleOperatorClick = (operatorName: string) => {
    const tableData = {
      selected: selectedRowKeys,
      refreshList: () => {
        run();
        setSelectedRowKeys([]);
      },
      setCheckedRowKeys,
    };
    // 判断是否有component字段
    const operator = operators.find(
      (operator) => operator.name === operatorName
    );
    if (operator) {
      if (operator.component) {
        // crud
        const type = operator.component.type;
        const dialogId = nanoid();
        if (type === "crud") {
          executeCrudEvent(operator, {
            crudDialogRef,
            crudInfoFormRef,
            selectedRowKeys,
            tableData
          })
        } else if (type === "assign") {
          crudDialogRef.current?.openDialog({
            id: dialogId,
            title: operator.title,
            content: (
              <AssignInfoForm
                ref={assignInfoFormRef}
                pagination_ids={selectedRowKeys as string[]}
                info_api={operator.component.info_api}
              />
            ),
            width: 860,
            onOk: async () => {
              try {
                let { data } = await assignInfoFormRef.current.submit();
                if (data) {
                  message.success(data.detail);
                }
                tableData.refreshList();
                crudDialogRef?.current?.closeDialog();
              } catch (e: any) {
                console.error(e);
                message.error(e?.response?.data?.detail || "Error");
              }
            },
          });
        }
        return;
      }
    }
    if (props.operationFuncs) {
      const func = props.operationFuncs[operatorName];
      if (func) {
        func(tableData);
      }
    }
  };

  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);

  const setCheckedRowKeys = (keys: React.Key[]) => {
    setSelectedRowKeys(keys);
  };

  const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
    setSelectedRowKeys(newSelectedRowKeys);
    props.onCheckedChange && props.onCheckedChange(newSelectedRowKeys);
  };
  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
    fixed: true,
  };

  useEffect(() => {
    getSearcherRun();
  }, []);

  // useEffect(() => {
  //   if (!tableBodyHeight) {
  //     setTimeout(() => {
  //       upateTableHeight();
  //     }, 100);
  //   }
  // }, [tableBodyHeight]);

  useEffect(() => {
    if (hasGetFilters) {
      run();
    }
  }, [pagination.page_num, pagination.order_by, pagination.filter_by]);

  useEffect(() => {
    if (searchers && hasGetFilters) {
      const defaultFilterBy = searchers.reduce((prev, current) => {
        if (current.default) {
          prev[current.key || current.propKey] = current.default;
        }
        return prev;
      }, {} as Record<string, any>);
      setPagination((state) => {
        return {
          ...state,
          filter_by: defaultFilterBy,
        };
      });
    }
  }, [hasGetFilters]);

  useEffect(() => {
    const handleResize = () => {
      upateTableHeight();
      // setTableBodyHeight(0);
    };

    // handleResize();
    // window.addEventListener("resize", handleResize);
    let ob: any = null;
    if (tableRef.current) {
      const header = tableRef.current?.querySelector(".ant-table-header");
      if (header) {
        new ResizeObserver((entries) => {
          for (let entry of entries) {
            tableRef.current &&
              setTableBodyHeight(
                tableRef.current.offsetHeight -
                  entry.contentRect.height -
                  32 -
                  22
              );
          }
        }).observe(header);
      }
      ob = new ResizeObserver((entries) => {
        for (let entry of entries) {
          let headerHeight = header?.offsetHeight || 54;
          setTableBodyHeight(entry.contentRect.height - headerHeight - 32 - 22);
        }
      });
      ob.observe(tableRef.current);
    }
    // run();
    return () => {
      // window.removeEventListener("resize", handleResize);
      if (ob && tableRef.current) {
        ob.unobserve(tableRef.current);
      }
    };
  }, []);
  return (
    <Layout
      className={styles.layout}
      id={props.id}
      data-table-height={tableBodyHeight}
    >
      <Header className={styles.header}>
        <Filter
          filters={searchers}
          onSearch={handleSearch}
          operation={props.operation}
          onResetSorting={handleResetSorting}
        />
      </Header>
      {operators.length > 0 && (
        <Row style={{ backgroundColor: "#ffffff" }}>
          <Space style={{ marginBottom: "10px" }}>
            {operators.map((operator, index) => {
              let disabled = true;

              if (operator.type === CommonFilterOperationEnum.SELECTED) {
                // 有勾选展示
                if (selectedRowKeys.length) {
                  disabled = false;
                }
              } else if (
                operator.type === CommonFilterOperationEnum.SELECTED_ONE_ONLY
              ) {
                if (selectedRowKeys.length === 1) {
                  disabled = false;
                }
              } else {
                // 一直展示
                disabled = false;
              }
              return (
                <Button
                  disabled={disabled}
                  key={operator.name}
                  onClick={() => handleOperatorClick(operator.name)}
                >
                  {operator.title}
                </Button>
              );
            })}
          </Space>
        </Row>
      )}
      <Table
        ref={tableRef}
        className={styles.content}
        rowKey={props.rowKey}
        columns={columnsWithSorting}
        dataSource={tableDatas}
        rowSelection={rowSelection}
        pagination={{
          current: pagination.page_num,
          pageSize: pagination.per_page_num,
          total: pagination.total_num,
          showSizeChanger: true,
          pageSizeOptions: ["10", "25", "50"],
          onShowSizeChange(current, size) {
            setPagination((state) => {
              return {
                ...state,
                per_page_num: size,
              };
            });
          },
          showTotal(total, range) {
            return `共 ${total} 条`;
          },
        }}
        onChange={onChange}
        scroll={{ y: tableBodyHeight }}
      ></Table>
      {/* <Footer className={styles.footer}>footer</Footer> */}
    </Layout>
  );
};

export default CommonFilterTablePagination;
